@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
}

body > div {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
}

/**
 * Some styles that were not possible to customize through Material UI
 * mechanisms. Resorting to good ol global CSS here
 */

.Mui-selected {
  font-weight: 600 !important;
}

